export default class FontStyle {
  color: string;
  family: string;

  constructor(color: string, family: string){
    this.color = color;
    this.family = family;
  }

  toPojo(): any{
    return {
      color: this.color,
      family: this.family,
    }
  }

  static fromPojo(pojo: any){
    if (pojo){
      return new FontStyle(pojo.color, pojo.family);
    } else {
      return new FontStyle('', '')
    }
    
  }

  getStyleString(){
    return `{color: ${this.color}; font-family: ${this.family};}`
  }
}