import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCbCKq5D-a9tzVn1he8njuaxV9dNqdYZNI",
  authDomain: "storytrain-2f359.firebaseapp.com",
  databaseURL: "https://storytrain-2f359.firebaseio.com",
  projectId: "storytrain-2f359",
  storageBucket: "storytrain-2f359.appspot.com",
  messagingSenderId: "523758468577",
  appId: "1:523758468577:web:45db3136dfc59579ece6a0",
  measurementId: "G-FRC0WQZPEV"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
