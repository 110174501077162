import React from "react";
import WordModel from "../models/wordModel";
import FontStyle from "../models/fontStyle";

interface WordProps {
  wordObject: WordModel,
  fontStyle: FontStyle|undefined,
}

function Word(props: WordProps){
  let word = props.wordObject.word;
  const fontStyle = props.fontStyle;
  const startCharCode = word.charCodeAt(0);
  if ((48 <= startCharCode && startCharCode <= 57) || 
    (65 <= startCharCode && startCharCode <= 90) || 
    (97 <= startCharCode && startCharCode <= 122)){
      word = ' ' + word;
    }

  if (fontStyle){
    return (
      <span style={{color: fontStyle.color, fontFamily: fontStyle.family}}>{word}</span>
    );
  } else {
    return (
      <span>{word}</span>
    );
  }
  
}

export default Word;