import React from "react";
import {UserContext} from "../contexts/user-context"
import firebase from "firebase/app";
import "firebase/database";
import Word from "../word/Word"
import User from "../models/user";
import generateRandomName from "../nameGenerator/nameGenerator";
import FontStyle from "../models/fontStyle";
import fontConfig from "./fontConfig";
import WordModel from "../models/wordModel";
import "./Story.css"
import TypingBubble from "../typingBubble/TypingBubble";
import UIfx from "uifx";

const scribbleAudio = require("../media/scribble1.wav");

const beep = new UIfx(scribbleAudio);

type StoryProp = {
  storyId: string
}
type StoryState = {
  words: WordModel[],
  nextWordOrd: number,
  nextWord: string,
  players: Map<string, User>,
  pseudonym: string,
  selectedFontColor: string,
  selectedFontFamily: string,
  title: string,
}

class Story extends React.Component<StoryProp, StoryState> {
  constructor(props: StoryProp){
    super(props)
    this.state = {
      words: [],
      nextWord: '',
      nextWordOrd: 0,
      players: new Map<string, User>(),
      pseudonym: '',
      selectedFontColor: '',
      selectedFontFamily: '',
      title: 'My Story Title',
    }
  }

  componentDidMount(){
    const wordsRef = firebase.database().ref('stories/' + this.props.storyId + '/words');
    wordsRef.on('child_added', snapshot => {
      const word = WordModel.fromPojo(snapshot.val());
      this.setState({
        words: [...this.state.words, word],
        nextWordOrd: parseInt(snapshot.key || '0') + 1,  // TODO: Change the zero
      })
      beep.play();
    });

    const pseudonymUrl = 'stories/' + this.props.storyId + '/players/' + this.context.uid + '/pseudonym';
    const pseudonymRef = firebase.database().ref(pseudonymUrl);
    pseudonymRef.on('value', snapshot => {
      const pseudonym = snapshot.val();
      if (!pseudonym){
        this.createNewPseudonym(null);
      } else {
        this.setState({pseudonym: pseudonym});
      }
    });

    const fontUrl = 'stories/' + this.props.storyId + '/players/' + this.context.uid + '/font';
    const fontRef = firebase.database().ref(fontUrl);
    fontRef.on('value', snapshot => {
      const fontPojo = snapshot.val();
      if (fontPojo){
        const font = FontStyle.fromPojo(fontPojo);
        this.setState({
          selectedFontColor: font.color,
          selectedFontFamily: font.family,
        })
      }
    });

    // Get the players anytime it updates
    const playersRef = firebase.database().ref('stories/' + this.props.storyId + '/players');
    playersRef.on('value', snapshot => {
      const players = new Map<string, User>();

      if (!snapshot.val()){
        this.setState({players: players});
      } else {
        for (let [uid, userPojo] of Object.entries(snapshot.val())){
          players.set(uid, User.fromPojo(userPojo))
        }
        this.setState({players: players});
      }
    });

    // Handle title changes
    const titleUrl = 'stories/' + this.props.storyId + '/title';
    const titleRef = firebase.database().ref(titleUrl);
    titleRef.on('value', snapshot => {
      const title = snapshot.val();
      if (title){
        this.setState({title});
      }
    });
  }

  handleFontColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    var fontColor = event.currentTarget.value;
    this.setState({selectedFontColor: fontColor});
    const url = 'stories/' + this.props.storyId + '/players/' + this.context.uid + '/font/color';
    firebase.database().ref(url).set(fontColor);
  }

  handleFontFamilyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const fontFamily = event.currentTarget.value
    this.setState({selectedFontFamily: fontFamily});
    const url = 'stories/' + this.props.storyId + '/players/' + this.context.uid + '/font/family';
    firebase.database().ref(url).set(fontFamily);
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({nextWord: event.currentTarget.value});

    let url = 'stories/' + this.props.storyId + '/typingBubbles/' + this.state.pseudonym;
    firebase.database().ref(url).set(firebase.database.ServerValue.TIMESTAMP);
  }

  handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.submitWord(this.state.nextWord);
    this.setState({
      nextWord: ''
    })
  }

  submitWord(word: string) {
    const url = 'stories/' + this.props.storyId + '/words/' + this.state.nextWordOrd
    firebase.database().ref(url).set({
      word: word,
      owner: this.context.uid,
      ord: this.state.nextWordOrd,
    });
  }
  
  /* Ask the user for a new pseudonym, then set it in firebase */
  createNewPseudonym(oldPseudonym: string|null){
    const defaultPseudonym = oldPseudonym || generateRandomName();
    let pseudonym = prompt('What is your pseudonym?', defaultPseudonym);
    if (!pseudonym){
      if (oldPseudonym){
        return;
      } else {
        pseudonym = generateRandomName();
        window.confirm(`No name eh?. Your name is "${pseudonym}" then.`);
      }
    }

    const pseudonymUrl = 'stories/' + this.props.storyId + '/players/' + this.context.uid + '/pseudonym';
    firebase.database().ref(pseudonymUrl).set(pseudonym);
  }
  
  /* Ask the user for a new title, then set it in firebase */
  createTitle = () => {
    let newTitle = prompt('What is the new title?', this.state.title);
    if (newTitle){
      const titleUrl = 'stories/' + this.props.storyId + '/title';
      firebase.database().ref(titleUrl).set(newTitle);
    }
  }

  render() {
    const colorOptions = fontConfig.colors.map((color) => 
      <option key={color.name} value={color.value}>{color.name}</option>
    );

    const familyOptions = fontConfig.families.map((fontFamily) => 
      <option key={fontFamily.name} value={fontFamily.value}>{fontFamily.name}</option>
    );

    let playersHtml: JSX.Element[] = [];
    this.state.players.forEach((user, uid) => {
      playersHtml.push((
      <span key={uid} 
        style={{color: user.fontStyle.color, fontFamily: user.fontStyle.family}}>{user.pseudonym}, </span>
      ));
    })

    const words = this.state.words.map((word) => 
      <Word key={word.ord} wordObject={word} 
      fontStyle={this.state.players.get(word.owner)?.fontStyle} />
    );

    const activeUsers = new Set(this.state.words.map(word => word.owner));
    console.log(activeUsers);
    const activePlayers: User[] = [];
    this.state.players.forEach((player, uid) => {
      console.log(player, uid);
      if (activeUsers.has(uid)){
        activePlayers.push(player);
      }
    });

    return (
      <div className="story">
        <p>
          Your Pseudonym: {this.state.pseudonym} 
          <button id="changepoetname" 
            onClick={() => this.createNewPseudonym(this.state.pseudonym)}>
              (change)
          </button>
        </p>
        <form>
          Color: 
          <select value={this.state.selectedFontColor} onChange={this.handleFontColorChange}>
            {colorOptions}
          </select>
          Font: 
          <select value={this.state.selectedFontFamily} onChange={this.handleFontFamilyChange}>
            {familyOptions}
          </select>
        </form>
        <p>Welcome to your own story. Change your font and color above, and invite friends: 
          <code>storytrain.org/story/{this.props.storyId}</code>. 
          Try typing one word at a time, or whole sentences! It's up to you. It's your story train :)
        </p>
        {/* <div>
          Who's playing:
          {playersHtml}
        </div> */}
        <div className="paper">
          <button onClick={this.createTitle}>
              (change)
          </button>
          <h1>{this.state.title}</h1>
          <h4>By: {activePlayers.map(user => user.pseudonym).join(', ')}</h4>
          <p>{words} 
            <TypingBubble storyId={this.props.storyId} />
          </p>
          <form onSubmit={this.handleSubmit}>
            <input type="text" value={this.state.nextWord} onChange={this.handleChange} />
          </form>
        </div>
      </div>
    );
  }
}

Story.contextType = UserContext;

export default Story;;