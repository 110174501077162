import React from "react";
import Story from "../models/story";
import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import {UserContext} from "../contexts/user-context"
import Button from "@material-ui/core/Button"

type HomeProps = {};
type HomeState = {
  stories: Story[],
  newStoryId: string|null
};

class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps){
    super(props);

    this.state = {
      stories: [],
      newStoryId: null,
    }
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({newStoryId: event.currentTarget.value});
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var newStoryId = this.state.newStoryId || this.createRandomStoryId();
    newStoryId = this.morphNewStoryId(newStoryId);
    this.state.stories.forEach(story => {
      if(story.id === newStoryId){
        window.alert('Story name already exists. Please don\'t plagiarize.')
        return;
      }
    });
    this.createNewStory(newStoryId);
  }
  
  createRandomStoryId(): string{
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }

  morphNewStoryId(newStoryId: string): string{
    newStoryId = newStoryId.trim(); // trim leading and trailing whitespace
    newStoryId = newStoryId.replace(/\s/g, '_');  // replace whitespace with underscore
    return newStoryId;
  }

  createNewStory(new_story_id: string){
    if (!new_story_id){
      alert('Story ID is empty. Ignoring request.');
      return;
    }

    firebase.firestore().collection('stories_index').add({
      id: new_story_id,
      creator: this.context.uid,
      creation_date: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(function(docRef) {
        console.log('New story index saved: ', docRef.id);
        window.open('/story/' + new_story_id);
    })
    .catch(function(error) {
        console.error("Error adding story index: ", error);
    });

    const stories_url = 'stories/' + new_story_id;
    firebase.database().ref(stories_url).set({
      id: new_story_id,
      creator: this.context.uid,
      creation_date: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  render() {
    return (
      <div className="home">
        <h1>Story Train</h1>
        <h2>Where you and friends or strangers write stories together!  Choo Choo!</h2>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label>
              <input placeholder="Title..." type="value" onChange={this.handleChange} />
            </label>
            <div className="hint">(If left empty, a random title will be made for you)</div>
          </div>
          <div>
            <Button variant="contained" color="primary" type="submit">New Story</Button>
          </div>
        </form>
      </div>
    );
  }
}

Home.contextType = UserContext;

export default Home;