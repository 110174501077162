export default {
  colors: [
    {'value': 'black', 'name': 'Meteorite'},
    {'value': '#f272dd', 'name': 'Summer Pink'},
    {'value': '#00d8e3', 'name': 'Rick Blue'},
    // {'value': '#ffef0f', 'name': 'Morty Yellow'},
    {'value': '#f28f38', 'name': 'Cronenberg Orange'},
    {'value': '#590202', 'name': 'Beautiful Brown'},
    {'value': 'green', 'name': 'Portal Green'},
    {'value': '#9678b6', 'name': 'Purple Mountain Majesty'},
  ],
  families: [
    {'value': 'arial', 'name': 'arial'},
    {'value': 'serif', 'name': 'serif'},
    {'value': 'courier', 'name': 'courier'},
    {'value': 'Comic Sans MS', 'name': 'comicsans'},
  ]
}