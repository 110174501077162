import User from "./user";
import WordModel from "./wordModel";

export default class Story {
  id: string;
  creator: string|null;
  players: Map<string, User>;
  words: Map<number, WordModel>;

  constructor(id: string){
    this.id = id;
    this.creator = null;
    this.players = new Map<string, User>();
    this.words = new Map<number, WordModel>();
  }

  toPojo(): any{
    let pojo: any = {
      id: this.id,
      creator: this.creator,
      players: {},
      words: {},
    };

    this.players.forEach((user, uid) => {
      pojo.players[uid] = user.toPojo();
    });

    this.words.forEach((word, ord) => {
      pojo.words[ord] = word.toPojo();
    });

    return pojo;
  }

  static fromPojo(pojo: any): Story{
    let id = pojo.id || pojo.title;
    let story = new Story(id);
    story.creator = pojo.creator || null;

    if (pojo.players) {
      for (let [uid, userPojo] of Object.entries(pojo.players)){
        story.players.set(uid, User.fromPojo(userPojo))
      }
    }
    
    if (pojo.words){
      for (let [ord, wordPojo] of Object.entries(pojo.words)){
        story.words.set(parseInt(ord), WordModel.fromPojo(wordPojo))
      }
    }
    
    return story;
  }

  static fromIndexPojo(pojo: any): Story{
    return new Story(pojo.id);
  }
}