import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "./TypingBubble.css"

const timeoutTime = 500; // ms

interface TypingBubbleProps {
  storyId: string,
}

interface TypingBubbleState {
  typing: string[],
}

class TypingBubble extends React.Component<TypingBubbleProps, TypingBubbleState> {
  constructor(props: TypingBubbleProps){
    super(props)
    this.state = {
      typing: [],
    }
  }

  componentDidMount(){
    const typingBubbleUrl = 'stories/' + this.props.storyId + '/typingBubbles';
    const typingBubbleRef = firebase.database().ref(typingBubbleUrl);    
    typingBubbleRef.on('child_changed', snapshot => {
      if (snapshot.key == null){
        return;
      }
      
      this.setState((prevState) => { return {typing: [...prevState.typing, snapshot.key||'']}});

      setTimeout(() => {
        this.setState((prevState) => {return {typing: prevState.typing.slice(1)}});
      }, timeoutTime)
    });
  }

  render() {
    if (this.state.typing.length > 0){
      return <span className="typingBubble"> (typing)</span>
    } else {
      return null;
    }
  }
}

export default TypingBubble;
