import React from "react";
import {withRouter} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import Story from "./Story";

class StoryGuard extends React.Component {

  constructor(props){
    super(props);
    this.state = {isValid: false};
  }

  componentDidMount(){
    const story_id = this.props.match.params.story_id;
    const collection = firebase.firestore().collection('stories_index');
    const storyIndexQuery = collection.where('id', '==', story_id);
    storyIndexQuery.get()
    .then(snapshot => {
      if (snapshot.empty){
        this.redirectHome();
        return;
      } else {
        this.setState({isValid: true});
      }
    });
  }

  redirectHome(){
    console.log('Not a valid game: ' + this.props.match.params.game_id)
    this.props.history.push('/');
  }

  render() {
    if (this.state.isValid){
      return <Story storyId={this.props.match.params.story_id} />
    } else {
      return null;
    }
    
  }
}

export default withRouter(StoryGuard);