export default class WordModel {
  ord: number;
  owner: string;
  word: string;

  constructor(ord: number, owner: string, word: string){
    this.ord = ord;
    this.owner = owner;
    this.word = word;
  }

  toPojo(): any{
    return {
      ord: this.ord,
      owner: this.owner,
      word: this.word,
    }
  }

  static fromPojo(pojo: any){
    return new WordModel(pojo.ord, pojo.owner, pojo.word);
  }
}