import React from "react";
import Story from "../models/story";
import {Link} from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/database";
import {UserContext} from "../contexts/user-context"

type AdminProps = {};
type AdminState = {
  stories: Story[],
};

class Admin extends React.Component<AdminProps, AdminState> {
  constructor(props: AdminProps){
    super(props);

    this.state = {
      stories: []
    };
  }

  componentDidMount(){
    const url = 'stories';
    const self = this;
    firebase.database().ref(url).on('child_added', function(snapshot){
      const storyIndex = snapshot.val();
      self.setState(currState => {
        return {stories: [...currState.stories, Story.fromPojo(storyIndex)]};
      })
    });
  }

  deleteStory(storyId: string){
    const confirmMessage = `Are you sure you want to delete the story:${storyId}?`;
    if(window.confirm(confirmMessage)){
      const storiesUrl = 'stories/' + storyId;
      firebase.database().ref(storiesUrl).remove();
    }
  }

  render() {
    const storiesList = this.state.stories.map((story: Story) => (
      <li key={story.id}><Link to={'/story/' + story.id}>{story.id} - {story.words.size} u, {story.players.size} p</Link>
        <button 
            onClick={() => this.deleteStory(story.id)}>
              remove
          </button>
      </li>
    ));

    return (
      <div>
        <h1>Admin Page</h1>
        <h3>All Stories:</h3>
        <ul>
          {storiesList}
        </ul>
      </div>
    );
  }
}

Admin.contextType = UserContext;

export default Admin;