import React, {useState, useEffect} from "react";
import {UserContext} from "./contexts/user-context";
import StoryGuard from "./story/StoryGuard";
import Home from "./home/Home";
import Admin from "./admin/Admin";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route  
} from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import loadingMessages from "./data/loadingMessages"

export default function App() {
  firebase.auth().signInAnonymously().catch(function(error) {
    alert('Error with logging in anonymously. Please let Patrick know. Error message: ' + error.message)
  });

  const [user, setUser] = useState({ loggedIn: false });

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  if (!user.loggedIn) {
    return <span>{loadingMessages.messages[Math.floor(Math.random() * loadingMessages.messages.length)]}</span>;
  }

  return (
    <UserContext.Provider value={user}>
      <Router>
        <div>
          <Switch>
            <Route path="/story/:story_id">
              <StoryGuard />
            </Route>
            <Route path="/story">
              <Redirect to="/" />
            </Route>
            <Route path="/patrick">
              <Admin />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </UserContext.Provider>
  );
}

function onAuthStateChange(callback) {
  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      callback({loggedIn: true, isAnonymous: user.isAnonymous, uid: user.uid});
    } else {
      callback({loggedIn: false});
    }
  });
}
