import FontStyle from "./fontStyle"

export default class User {
  pseudonym: string;
  fontStyle: FontStyle;

  constructor(pseudonym: string, fontStyle: FontStyle){
    this.pseudonym = pseudonym;
    this.fontStyle = fontStyle;
  }

  toPojo(): any{
    return {
      pseudonym: this.pseudonym,
      fontStyle: this.fontStyle.toPojo()
    }
  }

  static fromPojo(pojo: any){
    return new User(pojo.pseudonym, FontStyle.fromPojo(pojo.font))
  }
}